.side-navigation-panel .side-navigation-panel-select .side-navigation-panel-select-wrap .side-navigation-panel-select-option {
    color: #fff!important;
    background-color: #3A4C63!important;
    border-color: #3A4C63!important;
}
.side-navigation-panel *, .side-navigation-panel:after, .side-navigation-panel:before {
    
    border-style: none!important;
    
  
}
.side-navigation-panel .side-navigation-panel-select .side-navigation-panel-select-wrap .side-navigation-panel-select-option:hover {
    background-color: #3A4C63!important;
    border-color: #3A4C63!important;
    color: #fffF!important;
}

.side-navigation-panel .side-navigation-panel-select .side-navigation-panel-select-inner .side-navigation-panel-select-inner-wrap .side-navigation-panel-select-inner-option:hover{
    --tw-border-opacity: 1;
    --tw-bg-opacity: 1;
    --tw-text-opacity: 1;
    background-color: #3A4C63!important;
    border-color: #3A4C63!important;
    color: #fff!important;
}
.side-navigation-panel .side-navigation-panel-select .side-navigation-panel-select-inner .side-navigation-panel-select-inner-wrap .side-navigation-panel-select-inner-option{
    color: #fff!important;
    background-color: #3A4C63!important;
    border-color: #3A4C63!important;
    padding: 10px 25px!important;
    
}
/* .side-navigation-panel .side-navigation-panel-select .side-navigation-panel-select-wrap .side-navigation-panel-select-option-selected{

} */

.side-navigation-panel .side-navigation-panel-select .side-navigation-panel-select-inner .side-navigation-panel-select-inner-wrap .side-navigation-panel-select-inner-option .side-navigation-panel-select-inner-option-wrap .side-navigation-panel-select-inner-option-text {
    font-size: 0.768rem!important;}

/* .form-check-input.siteSwith:checked {
    background-color: #f7c94a!important;
    border-color: #f7c94a!important;
} */