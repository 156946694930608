.categoryName:hover {
    color: #00ccb4
}
/* .active-Page:active{
    color: #f7c94a !important;
} */
.page-link {
  cursor: pointer;
}
.page-link.active {
  background-color:#f7c94a;
  border-color:#f7c94a;
}