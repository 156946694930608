.guided-tour {
	overflow: hidden;
}
.guided-tour-step {
	display: none;
	text-rendering: optimizespeed;
	font-size: 12pt;
	color: #333;
}
.guided-tour-step.active {
	display: block;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 1024;
	pointer-events: all;
}
.guided-tour-step.active .guided-tour-step-highlight {
	position: absolute;
	box-sizing: border-box;
	border-radius: 4px;
	box-shadow: 0 0 0 999em rgba(0, 0, 0, 0.5);
	z-index: 1;
}
.guided-tour-step.active .guided-tour-step-tooltip {
	position: absolute;
	padding: 20px;
	margin: 16px 0;
	z-index: 2;
	background-color: #fff;
	max-width: 400px;
	border-radius: 5px;
	box-sizing: border-box;
	box-shadow: 0 0 8px -4px #000;
	transition: opacity 150ms;
	min-width: 250px;
}
@media screen and (max-width: 760px) {
	.guided-tour-step.active .guided-tour-step-tooltip {
		max-width: unset;
		width: calc(100% - 30px);
		left: 15px !important;
		right: 15px !important;
	}
}
.guided-tour-step.active .guided-tour-step-tooltip .guided-tour-arrow {
	position: absolute;
	border-top: 8px solid transparent;
	border-left: 8px solid transparent;
	border-right: 8px solid transparent;
	border-bottom: 8px solid transparent;
	margin-left: -8px;
	margin-top: -8px;
}
.guided-tour-step.active .guided-tour-step-tooltip.guided-tour-arrow-none .guided-tour-arrow {
	display: none;
}
.guided-tour-step.active .guided-tour-step-tooltip.guided-tour-arrow-top .guided-tour-arrow {
	border-bottom-color: #fff;
	margin-top: -16px;
	top: 0;
}
.guided-tour-step.active .guided-tour-step-tooltip.guided-tour-arrow-bottom .guided-tour-arrow {
	border-top-color: #fff;
	margin-bottom: -16px;
	bottom: 0;
}
.guided-tour-step.active .guided-tour-step-tooltip.guided-tour-center {
	transform: translate(-50%, -50%);
}
@media screen and (max-width: 760px) {
	.guided-tour-step.active .guided-tour-step-tooltip.guided-tour-center {
		transform: translate(0, -50%);
	}
}
.guided-tour-step.active .guided-tour-step-tooltip .guided-tour-step-image img {
	width: 100%;
	height: auto;
	border-radius: 4px;
	margin-bottom: 10px;
}
.guided-tour-step.active .guided-tour-step-tooltip .guided-tour-step-title {
	font-weight: 500;
	margin-bottom: 3px;
	padding-left: 3px;
}
.guided-tour-step-content {
	font-size: 13px;
	padding-left: 3px;
	margin-bottom: 7px;
}
@media screen and (max-width: 760px) {
	.guided-tour-step.active .guided-tour-step-tooltip .guided-tour-step-title {
		padding-right: 15px;
	}
}
@media screen and (max-width: 760px) {
	.guided-tour-step.active .guided-tour-step-tooltip .guided-tour-step-content {
		padding-right: 15px;
	}
}
.guided-tour-step.active .guided-tour-step-tooltip .guided-tour-icon {
	display: inline-block;
	overflow: hidden;
	width: 22px;
}
.guided-tour-step.active .guided-tour-step-tooltip .guided-tour-step-button {
	flex-direction: column;
	justify-content: center;
	display: inline-flex;
	text-align: center;
	cursor: pointer;
}
.guided-tour-step.active .guided-tour-step-tooltip .guided-tour-step-button .guided-tour-icon {
	align-self: center;
}
.guided-tour-step.active .guided-tour-step-tooltip .guided-tour-step-button-next,
.guided-tour-step.active .guided-tour-step-tooltip .guided-tour-step-button-complete {
	border: 0px solid currentColor;
	box-shadow: none;
	width: 38px;
	height: 38px;
	background: #fff;
	border-radius: 50%;
	position: absolute;
	margin-top: -44px;
	right: 1px;
	top: 100%;
}
.guided-tour-step-image {
	max-height: 12vw;
	align-items: center;
	justify-content: center;
	display: flex;
	overflow: hidden;
}
@media screen and (max-width: 760px) {
	.guided-tour-step.active .guided-tour-step-tooltip .guided-tour-step-button-next,
	.guided-tour-step.active .guided-tour-step-tooltip .guided-tour-step-button-complete {
		right: -10px;
	}
}
.guided-tour-step.active .guided-tour-step-tooltip .guided-tour-step-button-next {
	color: #00ccb4;
}
.guided-tour-step.active .guided-tour-step-tooltip .guided-tour-step-button-complete {
	color: #00ccb4;
}
.guided-tour-step.active .guided-tour-step-tooltip .guided-tour-step-button-close {
	position: absolute;
	top: -10px;
	right: -10px;
	width: 32px;
	height: 32px;
	background: #fff;
	border-radius: 30px;
	box-shadow: 5px 0 1em 0 rgb(0 0 0 / 40%);
}
.guided-tour-step.active .guided-tour-step-tooltip .guided-tour-step-footer-bullets {
	text-align: center;
	line-height: 16px;
}
.guided-tour-step.active .guided-tour-step-tooltip .guided-tour-step-footer-bullets ul {
	margin: 0;
	list-style: none;
	padding: 0;
	margin-bottom: -10px;
}
.guided-tour-step.active .guided-tour-step-tooltip .guided-tour-step-footer-bullets ul li {
	width: 8px;
	height: 8px;
	border-radius: 50%;
	padding: 0;
	display: inline-block;
	background-color: #00ccb4;
	border: 8px solid #fff;
	box-sizing: content-box;
	cursor: pointer;
}
.guided-tour-step.active .guided-tour-step-tooltip .guided-tour-step-footer-bullets ul li.complete {
	background-color: #aaa;
}
.guided-tour-step.active .guided-tour-step-tooltip .guided-tour-step-footer-bullets ul li.current {
	background-color: #00ccb4;
}
.guided-tour-step.active .guided-tour-step-tooltip .guided-tour-step-footer-bullets ul li:last-of-type {
	margin-right: 0;
}
#GuidedTourIconSet {
	display: none;
}
