.form-check-input:checked {
  background-color: #f7c94a !important;
  border-color: #f7c94a !important;
}
/* .customPagination .page-item.active .page-link {
    background: #f7c94a!important;
    border-color: #f7c94a!important;
    color: #fff!important;
} */
.page-link:hover {
  background-color: #f7c94a !important;
  border-color: #f7c94a !important;
}
@import "./assests/style.css";
@import "./assests/custom.css";
@import "./assests/sidebar.css";
/* @import "./assests/intlTelInput.css"; */
@import "./assests/viewer.min.css";
@import "./assests/tourguide.css";

/* old css */
/* @import "./assests/vosmos/css/custom.css"; */
/* @import "./assests/vosmos/css/style.css"; */
/* @import "./assests/vosmos/css/button.css"; */

/* @import "./assests/vosmos/css/intlTelInput.css";
@import "./assests/vosmos/css/scrollbar.css"; */
/* @import "./assests/styleAuth"; */
/* @import "./assests/vosmos/css/reset.css";
@import "./assests/vosmos/css/zIndex.css";
@import "./assests/vosmos/css/font.css";
@import "./assests/vosmos/css/date.css";
@import "./assests/vosmos/css/audioplayer.css";
@import "./assests/vosmos/css/loading.css";
@import "./assests/vosmos/css/nice-select.css";
@import "./assests/vosmos/css/color.css"; */

.navbar .nav-item .dropdown-toggle::after {
  display: none;
}
/* .form-check-input.siteSwith:checked {
    background-color: #f7c94a!important;
    border-color: #f7c94a!important;
} */

.vosmosRangeSlider > input[type="range"] {
  -webkit-appearance: none;
  margin-right: 15px;
  width: 200px;
  height: 4px;
  background: #ededed;
  border-radius: 5px;
  background-image: linear-gradient(#00cbb3, #00cbb3);
  background-size: 70% 100%;
  background-repeat: no-repeat;
}
.vosmosRangeSlider > input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background: #00cbb3;
  cursor: ew-resize;
  transition: background 0.3s ease-in-out;
}
.vosmosRangeSlider > input[type="range"]::-moz-range-thumb {
  -webkit-appearance: none;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background: #00cbb3;
  cursor: ew-resize;
  transition: background 0.3s ease-in-out;
}
.vosmosRangeSlider > input[type="range"]::-ms-thumb {
  -webkit-appearance: none;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background: #00cbb3;
  cursor: ew-resize;
  transition: background 0.3s ease-in-out;
}
.vosmosRangeSlider > input[type="range"]::-webkit-slider-thumb:hover {
  background: #f7c94a;
}
.vosmosRangeSlider > input[type="range"]::-moz-range-thumb:hover {
  background: #f7c94a;
}
.vosmosRangeSlider > input[type="range"]::-ms-thumb:hover {
  background: #f7c94a;
}
.vosmosRangeSlider > input[type="range"]::-webkit-slider-runnable-track {
  -webkit-appearance: none;
  box-shadow: none;
  border: none;
  background: transparent;
}
.vosmosRangeSlider > input[type="range"]::-moz-range-track {
  -webkit-appearance: none;
  box-shadow: none;
  border: none;
  background: transparent;
}
.vosmosRangeSlider > input[type="range"]::-ms-track {
  -webkit-appearance: none;
  box-shadow: none;
  border: none;
  background: transparent;
}

.toastBoxNew .check {
  height: 35px;
  width: 35px;
  min-width: 35px;
}

.Toastify .Toastify__toast {
  color: #3a4c63;
}

.Toastify .Toastify__toast {
  padding: 12px;
  border-radius: 12px;
  background: #fff;
}

.Toastify .Toastify__toast-icon {
  display: none;
}

.Toastify .Toastify__progress-bar {
  height: 4px;
}

.Toastify .Toastify__progress-bar--success {
  background: #00ccb4;
}

.Toastify .Toastify__progress-bar--info {
  background: #3a4c63;
}

.Toastify .Toastify__progress-bar--warn {
  background: #f7c94a;
}

.Toastify .Toastify__progress-bar--error {
  background: #e82a2a;
}

.Toastify__toast--success .check {
  background: #00ccb4;
}

.Toastify__toast--info .check {
  background: #3a4c63;
}

.Toastify__toast--warning .check {
  background: #f7c94a;
}

.Toastify__toast--error .check {
  background: #e82a2a;
}

.Toastify__toast--default .check {
  display: none !important;
}

.Toastify .Toastify__progress-bar--default {
  height: 0px;
  overflow: hidden;
}

.minWidth24 {
  min-width: 24px;
}
